import { createWebHistory, createRouter } from "vue-router";

// Pages 
import LoginComponent from '@/views/auth/LoginPage'
import IndexPage from '@/views/home/IndexPage'

import InvoicesIndexPage from '@/views/invoices/InvoicesIndexPage'
import InvoicesShowPage from '@/views/invoices/InvoicesShowPage'
import InvoicesCreatePage from '@/views/invoices/InvoicesCreatePage'

import ClientsIndexPage from '@/views/clients/ClientsIndexPage'
import ClientsCreatePage from '@/views/clients/ClientsCreatePage'
import ClientsShowPage from '@/views/clients/ClientsShowPage'

import LibrariesEditPage from '@/views/libraries/LibrariesEditPage'

import CitiesIndexPage from '@/views/cities/CitiesIndexPage'
import CitiesCreatePage from '@/views/cities/CitiesCreatePage'

import LogsIndexPage from '@/views/logs/LogsIndexPage'

import NotificationCreatePage from '@/views/notifications/NotificationCreatePage'

import CategoriesIndexPage from "@/views/Categories/CategoriesIndexPage.vue";
import CategoriesCreatePage from "@/views/Categories/CategoriesCreatePage.vue";

import NotFound from '@/views/customs/NotFoundPage'

const routes = [
   { path: '/', name: 'login', component: LoginComponent },
   
   { path: '/home', name: 'home', component: IndexPage },

   { path: '/invoices', name: 'invoices', component: InvoicesIndexPage },
   { path: '/invoices/:id/show', name: 'invoices-show', component: InvoicesShowPage },
   { path: '/invoices/:id/create', name: 'invoices-create', component: InvoicesCreatePage },

   { path: '/clients', name: 'clients-index', component: ClientsIndexPage },
   { path: '/clients/create', name: 'clients-create', component: ClientsCreatePage },
   { path: '/clients/:id/show', name: 'clients-show', component: ClientsShowPage },

   { path: '/libraries/:id/edit', name: 'libraries-edit', component: LibrariesEditPage },

   { path: '/cities', name: 'cities-index', component: CitiesIndexPage },
   { path: '/cities/create', name: 'cities-create', component: CitiesCreatePage },
   
   { path: '/logs', name: 'logs', component: LogsIndexPage },

   { path: '/notifications/:id', name: 'notifications', component: NotificationCreatePage },

   { path: '/categories/dewey', name: 'categories-dewey-index', component: CategoriesIndexPage },
   { path: '/categories/dewey/create', name: 'categories-dewey-create', component: CategoriesCreatePage },

   { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound },
];

const router = createRouter({
   history: createWebHistory(),
   routes,
});
 
export default router;